// Checks if new post has been added

import { createContext, useContext, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { portalAPI } from "api/helper";
import { t } from "i18next";
import { useGetSessionToken } from "./SessionProvider";

export interface LatestPostIdQuery {
  data: {
    posts: {
      id: string;
      publishDate: string;
    }[];
  };
}

const QUERY = `query GetLatestPostId {
    posts(orderBy: { publishDate: desc }, take: 1) {
      id
      publishDate
    }
  }  
  `;

const GetLatestPostId = async (token: string | undefined): Promise<string> => {
  try {
    const response = await portalAPI<LatestPostIdQuery>({
      path: "graphql",
      query: QUERY,
      token,
    });
    return response.data.posts[0].id;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(t("messages.failedToFetch", { error: error.message }));
    } else {
      throw new Error(t("messages.error"));
    }
  }
};

const PostNotificationContext = createContext<{
  newPost: boolean;
  isLoading: boolean;
  setPostIdToLocalstorage: (postId: string) => void;
}>({
  newPost: false,
  isLoading: false,
  setPostIdToLocalstorage: (postId: string) =>
    localStorage.setItem("latestPostId", postId),
});

export const PostNotificationProvider: React.FC = ({ children }) => {
  const [newPost, setNewPost] = useState(false);
  const token = useGetSessionToken();

  const { isLoading } = useQuery<string>(
    ["latestPostId"],
    () => GetLatestPostId(token),
    {
      enabled: !!token,
      refetchInterval: 36000,
      onSuccess: (data) => {
        setNewPost(localStorage.getItem("latestPostId") !== data);
      },
    }
  );

  const setPostIdToLocalstorage = (postId: string) => {
    localStorage.setItem("latestPostId", postId);
    setNewPost(false);
  };

  return (
    <PostNotificationContext.Provider
      value={{ newPost, isLoading, setPostIdToLocalstorage }}
    >
      {children}
    </PostNotificationContext.Provider>
  );
};

export const usePostNotification = () => useContext(PostNotificationContext);
